import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3bba5881 = () => interopDefault(import('../pages/config.ts' /* webpackChunkName: "pages/config" */))
const _078d251b = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _6d30ea70 = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _0522c557 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _a195108c = () => interopDefault(import('../pages/reservation.vue' /* webpackChunkName: "pages/reservation" */))
const _361a84e6 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _28e05acc = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _27e69f0f = () => interopDefault(import('../pages/account/notification.vue' /* webpackChunkName: "pages/account/notification" */))
const _bf631ac6 = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _1befdebf = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _0bd356ae = () => interopDefault(import('../pages/reservations/history.vue' /* webpackChunkName: "pages/reservations/history" */))
const _4f060c18 = () => interopDefault(import('../pages/reservations/list.vue' /* webpackChunkName: "pages/reservations/list" */))
const _4c132eb4 = () => interopDefault(import('../pages/service/contact.vue' /* webpackChunkName: "pages/service/contact" */))
const _26a5b042 = () => interopDefault(import('../pages/service/coupon.vue' /* webpackChunkName: "pages/service/coupon" */))
const _c068d522 = () => interopDefault(import('../pages/service/news.vue' /* webpackChunkName: "pages/service/news" */))
const _eb13e308 = () => interopDefault(import('../pages/service/notifications.vue' /* webpackChunkName: "pages/service/notifications" */))
const _b9818bc8 = () => interopDefault(import('../pages/service/privacy.vue' /* webpackChunkName: "pages/service/privacy" */))
const _779dd0ca = () => interopDefault(import('../pages/service/terms.vue' /* webpackChunkName: "pages/service/terms" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/config/",
    component: _3bba5881,
    pathToRegexpOptions: {"strict":true},
    name: "config"
  }, {
    path: "/forget_password/",
    component: _078d251b,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/inspire/",
    component: _6d30ea70,
    pathToRegexpOptions: {"strict":true},
    name: "inspire"
  }, {
    path: "/login/",
    component: _0522c557,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reservation/",
    component: _a195108c,
    pathToRegexpOptions: {"strict":true},
    name: "reservation"
  }, {
    path: "/reset_password/",
    component: _361a84e6,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/signup/",
    component: _28e05acc,
    pathToRegexpOptions: {"strict":true},
    name: "signup"
  }, {
    path: "/account/notification/",
    component: _27e69f0f,
    pathToRegexpOptions: {"strict":true},
    name: "account-notification"
  }, {
    path: "/account/overview/",
    component: _bf631ac6,
    pathToRegexpOptions: {"strict":true},
    name: "account-overview"
  }, {
    path: "/account/password/",
    component: _1befdebf,
    pathToRegexpOptions: {"strict":true},
    name: "account-password"
  }, {
    path: "/reservations/history/",
    component: _0bd356ae,
    pathToRegexpOptions: {"strict":true},
    name: "reservations-history"
  }, {
    path: "/reservations/list/",
    component: _4f060c18,
    pathToRegexpOptions: {"strict":true},
    name: "reservations-list"
  }, {
    path: "/service/contact/",
    component: _4c132eb4,
    pathToRegexpOptions: {"strict":true},
    name: "service-contact"
  }, {
    path: "/service/coupon/",
    component: _26a5b042,
    pathToRegexpOptions: {"strict":true},
    name: "service-coupon"
  }, {
    path: "/service/news/",
    component: _c068d522,
    pathToRegexpOptions: {"strict":true},
    name: "service-news"
  }, {
    path: "/service/notifications/",
    component: _eb13e308,
    pathToRegexpOptions: {"strict":true},
    name: "service-notifications"
  }, {
    path: "/service/privacy/",
    component: _b9818bc8,
    pathToRegexpOptions: {"strict":true},
    name: "service-privacy"
  }, {
    path: "/service/terms/",
    component: _779dd0ca,
    pathToRegexpOptions: {"strict":true},
    name: "service-terms"
  }, {
    path: "/",
    redirect: "/reservation/",
    name: "index"
  }, {
    path: "*",
    redirect: "/reservation/",
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
